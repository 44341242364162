.procuctRegister {
    &_inner {
        padding: 0 20px;
    }
    &_text {
        font-size: rem(15px, pc);
        &-gray {
            color: #999;
            text-align: center;
        }
    }
    &_btns {}
}