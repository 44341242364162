.strainNameArea {
    &_inner {}
    &_items {
        margin-top: 0;
        height: 540px;
        overflow-y: scroll;
    }
    &_item {
        border-bottom: 1px dotted #aaa;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:last-child {
            border-bottom: none;
        }
    }
    &_contents {
        padding: 0 10px;
    }
    &_neme {
        font-size: rem(15px, pc);
        font-weight: 600;
    }
    &_flex {
        margin-top: 10px;
        display: flex;
        align-items: baseline;
    }
    &_btn {
        min-width: 100px;
        height: 36px;
        background: linear-gradient(to bottom, #fff, #efefef);
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-right: 10px;
        & > a {
            width: 100%;
            height: 100%;
            text-decoration: none;
            color: $txtColor;
            font-size: rem(15px, pc);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }
    &_text {
        font-size: rem(15px, pc);
        margin-top: 0;
    }
}

