// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
btn

ボタン

Markup:
<div class="btns btns-hr">
	<ul class="btns_list">
		<li class="btns_item btns_item-4-12"><a href="" class="btn {$modifiers}">ボタン</a></li>
		<li class="btns_item btns_item-4-12"><button class="btn {$modifiers}">ボタン</button></li>
	</ul>
</div><!-- /.btns -->

.btn-red - $red
.btn-redLight - $redLight
.btn-blue - $blue
.btn-blueLight - $blueLight
.btn-gray - $gray
.btn-grayLight - $grayLight
.btn-gray-border - $gray-border

Styleguide #{$guideNum}
*/
.btn {
	$this: &;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 10px;
	background: $gray-light;
	text-decoration: none;
	cursor: pointer;
	color: inherit;
	border: 1px solid transparent;
	transition: all .4s;

	&[disabled] {
		opacity: .6 !important;
		cursor: default !important;
	}
}

.commonBtn {
	margin: 20px auto 0;
	height: 40px;
	border-radius: 30px;
	box-shadow: 0px 2px 0px 0px #ddd;
	position: relative;
	display: block;
    width: 95%;

	&_btn {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		font-size: rem(15px, pc);
		color: white;
		width: 100%;
		height: 100%;
		gap: 5px;
		margin-top: 0;
	}
	& > a {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		font-size: rem(15px, pc);
		color: white;
		width: 100%;
		height: 100%;
		gap: 5px;
		margin-top: 0;
		
	}
	&-newsIcon {
		& > a,button {
			&::before {
				content: url(/assets/images/top/btn_icon.png);
				line-height: 1;
			}
		}
	}
	&-loginIcon {
		& > a,button {
            &::before {
                content: url(/assets/images/global/btn/login_icon.png);
				line-height: 1;
            }
        }
	}
	&-memberIcon {
		& > a,button {
            &::before {
                content: url(/assets/images/global/btn/member_icon.png);
				line-height: 1;
            }
        }
	}
	&-aroowIcon {
		position: relative;
		& > a,button {
			&::before {
                content: url(/assets/images/global/btn/aroow_icon.png);
				line-height: 1;
				position: absolute;
				left: 10px;
            }
		}
	}
	&-aroowIcon02 {
		position: relative;
		& > a,button {
			&::before {
                content: url(/assets/images/global/btn/aroow_icon02.png);
				line-height: 1;
				position: absolute;
				left: 10px;
            }
		}
	}
	&-aroowIcon03 {
		position: relative;
		& > a,button {
			&::after {
                content: url(/assets/images/global/btn/aroow_icon03.png);
				line-height: 1;
				position: absolute;
				right: 10px;
				top: 10px;
            }
		}
	}
	&-aroowIcon04 {
		position: relative;
		& > a,button {
			&::after {
                content: url(/assets/images/global/btn/aroow_icon04.png);
				line-height: 1;
				position: absolute;
				right: 15px;
				top: 10px;
            }
		}
	}
	&-mente {
		position: relative;
		& > a,button {
			&::before {
                content: url(/assets/images/global/btn/mente.png);
				line-height: 1;
            }
		}
	}
	&-cart {
		position: relative;
		& > a,button {
			&::before {
                content: url(/assets/images/global/btn/cart.png);
				line-height: 1;
            }
		}
	}
	&-bil {
		position: relative;
		& > a {
			&::before {
				content: url(/assets/images/global/btn/bil.png);
				line-height: 1;
			}
		}
	}

	&-more {
		position: relative;
		gap: 10px;
		& > a {
			&::before {
				content: url(/assets/images/global/btn/more_icon.png);
				line-height: 1;
			}
		}
	}
	&-edit {
		& > a {
			&::before {
				content: url(/assets/images/global/btn/edit_icon.png);
				line-height: 1;
			}
		}
	}
	&-plus {
		& > a {
			&::before {
				content: url(/assets/images/global/btn/plus_icon.png);
				line-height: 1;
			}
		}
	}
	&-parts {
		& > a {
			&::before {
				content: url(/assets/images/global/btn/parts_icon.png);
				line-height: 1;
			}
		}
	}
	&-orange {
		background-color: $oarange;
	}
	&-blue {
		background-color: $blue;
		color: white;
		& > a,button {
			color: white;
		}
	}
	&-white {
		background-color: white;
		border: 1px solid $blue;
		box-shadow: none;
		border-bottom: 2px solid $blue;
		color: $blue;
		& > a {
			color: $blue !important;
		}
	}
}

@media screen and (min-width: $breakpoint-sp +1){
	.btn {
		&:hover {
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.btn {
		$this: &;
	}
}
