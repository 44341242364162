.loginForm {
    padding: 0 20px;
    &_title {
        font-size: rem(18px, pc);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray;
        height: 44px;
    }
    &_contents {
        padding: 20px 30px 25px;
        border-right: 1px solid#ddd;
        border-left: 1px solid#ddd;
        border-bottom: 1px solid#ddd;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    &_form {}
    &_error {
        color: $red;
        margin-top: 5px;
        margin-left: 5px;
        font-size: rem(14px, pc);
    }
    &_checkBox {
        margin-top: 16px;
        text-align: center;
        line-height: 1;
        & > input[type=checkbox] {
            margin-right: 2px;

        }
    }
    &_btn {
        
    }
    &_text {
        text-align: center;
        margin-top: 10px;
    }
    .form {
        &_grid {
            margin-top: 10px;
        }
        &_grid-v {}
        &_grid-borderDot {}
        &_col {
            font-size: rem(15px, pc);
            & > input {
                border-top: 1px solid #a5a5a5;
                border-right: 1px solid #e2e2e2;
                border-left: 1px solid #e2e2e2;
                border-bottom: 1px solid #e2e2e2;
                border-radius: 5px;
            }
        }
        &_col-3-12pc {}
        &_col-12-12sp {}
        &_note {}
        &_error {
            margin-top: 0;
        }
    }
}

@media screen and (max-width: $breakpoint-sp){
    .loginForm {
		&_title {}
		&_contents {}
		&_form {}
    }
    .form {
            &_grid {}
            &_grid-v {}
            &_grid-borderDot {}
            &_col {}
            &_col-3-12pc {}
            &_col-12-12sp {}
            &_note {}
            &_error {}
    }
    .indent10e {
    }

}