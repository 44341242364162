// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
check

check

Markup:
<div class="check">
    <ul class="check_list">
        <li class="check_item">
            <h2 class="headLine6">AHU1, 20T01419-A01AA, AJ200-EV-DD</h2>
            <div class="check_body">
                <p class="check_txt">
                    部品情報がありません。<br>
                    消耗品を一括で見積依頼します。
                </p><!-- /.check_txt -->
                <div class="check_box"><input type="checkbox"></div><!-- /.check_box -->
            </div><!-- /.check_body -->
        </li><!-- /.check_item -->
        <li class="check_item">
            <h2 class="headLine6">AHU1, 20T01419-A01AA, AJ200-EV-DD</h2>
            <div class="check_body">
                <p class="check_txt">
                    部品情報がありません。<br>
                    消耗品を一括で見積依頼します。
                </p><!-- /.check_txt -->
                <div class="check_box"><input type="checkbox"></div><!-- /.check_box -->
            </div><!-- /.check_body -->
        </li><!-- /.check_item -->
        <li class="check_item">
            <h2 class="headLine6">AHU1, 20T01419-A01AA, AJ200-EV-DD</h2>
            <div class="check_body">
                <p class="check_txt">
                    部品情報がありません。<br>
                    消耗品を一括で見積依頼します。
                </p><!-- /.check_txt -->
                <div class="check_box"><input type="checkbox"></div><!-- /.check_box -->
            </div><!-- /.check_body -->
        </li><!-- /.check_item -->
    </ul><!-- /.check_list -->
</div><!-- /.check -->

Styleguide #{$guideNum}
*/
.check {
    $this: &;
    margin: 30px 0 0;

    &_list {
        margin: 0;
        & > :first-child {
            margin-top: 0;
        }
    }

    &_item {
        margin: 30px 0 0;
        .headLine6 {
            margin: 0;
        }
    }

    &_body {
        margin: 20px 0 0;
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-start;
        gap: 0 20px;
    }

    &_txt {
        margin: 0;
        font-size: rem(17px, pc);
    }

    &_box {
        margin: 10px 0 0;
        width: 13px;
        height: 13px;
        position: relative;
        input[type="checkbox"] {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media screen and (max-width: $breakpoint-sp){
    .check {
        $this: &;
        margin: 30px 0 0;

        &_list {}

        &_item {}

        &_body {}

        &_txt {}

        &_box {
            margin: 6px 0 0;
            width: 44px;
            height: 44px;
            input[type="checkbox"] {
            }
        }
    }
}