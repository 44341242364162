.registerEnd {
    &_inner {}
    &_texts {
        padding: 0 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &_text {
        line-height: 2;
        &:last-child {
            margin-top: 0;
        }
    }
    &_btns {}
}
