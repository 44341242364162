.reissueBlock {
    &_inner {
        padding: 0 20px 27px;
    }
    &_text {
        margin-top: 0;
        font-size: rem(15px, pc);
        line-height: 2;
    }
    &_box {
        background-color: #f3f3f3;
        margin-top: 20px;
    }
    &_form {
        padding: 25px 17px;
        &Text {
            line-height: 1.6;
            font-size: rem(15px, pc);
        }
    }
    &_input {
        margin-top: 5px;
        border-radius: 5px;
        border-top: 1px solid #e5e5e5;
        border-right: 1px solid #e2e2e2;
        border-left: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
    }
}