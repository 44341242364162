// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine3

見出し3

Markup:
<h3 class="headLine3">見出し3</h3>

Styleguide #{$guideNum}
*/
.headLine3 {
	$this: &;
	margin: 40px 0 20px;
    padding: 0 0 5px 26px;
    text-indent: -26px;
    font-size: 1.375rem;
    border-bottom: 1px dotted #aaa;
    line-height: 1.4;
    font-weight: 700;

	&::before {
		content: "";
		margin-right: 12px;
		display: inline-block;
		text-indent: 0;
		width: 14px;
		height: 4px;
		background: #0081cc;
		border-radius: 4px;
		vertical-align: 0.3em;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine3 {
		$this: &;
	}
}
