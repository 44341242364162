// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
acc

アコーディオンの外側のモジュール
中身はaccの要素として、このファイル内で指定する（変にモジュール化すると影響範囲が分からなくなる）

Markup:
<div class="acc">
    <div class="acc_inner">
        <ul class="acc_list">
            <? for ( var n = 0; n < 8; n++ ) { ?>
            <li class="acc_item" data-acc>
                <div class="acc_btn" data-acc-btn>フィルタ</div><!-- /.acc_btn -->
                <div class="acc_content" data-acc-content>
                    <div class="acc_contentInner">

                        ここにアコーディオンの中身を指定

                    </div><!-- /.acc_contentInner -->
                </div><!-- /.acc_content -->
            </li><!-- /.acc_item -->
            <? } ?>
        </ul><!-- /.acc_list -->
    </div><!-- /.acc_inner -->
</div><!-- /.acc -->

Styleguide #{$guideNum}
*/
.acc {
    $this: &;
    margin: 20px 0 0;

    &_inner {
        & > :first-child {
            margin-top: 0;
        }
    }

    &_list {
        & > :last-child {
            .acc_scrollTableTd,
            .acc_selectList {
                border-bottom: 1px solid $gray02;
            }
        }
    }

    &_item {}

    &_btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0 16px;
        padding: 10px 16px 10px 18px;
        background: $gray;
        font-size: rem(15px, pc);
        font-weight: 600;
        transition: all .4s;
        user-select: none;
        &:hover {
            cursor: pointer;
            opacity: .6;
        }
        &::after {
            @include icons($icons-caret-down);
            font-size: .8em;
            font-weight: normal;
            line-height: 1;
        }

        //  disabled（確認ページで開いた状態のアコーディオンの機能がないものに使用）
        //----------------------------------------------------------------------
        &-disabled {
            &:hover {
                pointer-events: none;
            }
            &::after {
                content: none;
            }
        }
    }

    &_content {
        border-top: 1px solid #fff;
    }

    &_contentInner {}


    //======================================================================
    //
    //======================================================================
    //  ここからアコーディオンの中身を指定（要素ブロック）
    //======================================================================
    //
    //======================================================================


    //======================================================================
    //  acc_scrollTable
    //======================================================================
    &_scrollTable {
        margin: -1px 0 0;
        width: 100%;
        height: 100%;
        max-height: 335px;
        white-space: nowrap;
        overflow: auto;
		-webkit-overflow-scrolling: touch;
    }

    &_scrollTableTable {}

    &_scrollTableThead {
        position: sticky;
        top: 0;
        left: 0;
    }

    &_scrollTableTr {}

    &_scrollTableTh {
        // position: sticky;
        // top: 0;
        // left: 0;
        background: $gray;
        border-bottom: none;
        border-left: 1px solid $gray02;
        border-right: 1px solid $gray02;

        //  color
        //----------------------------------------------------------------------
        &-colorBlue {
            color: $blue;
        }
    }

    &_scrollTableTbody {
        // overflow: scroll;
        & > #{$this}_scrollTableTr {
            & > :first-child {
                // text-align: center;
            }
        }
    }

    &_scrollTableTd {
        font-weight: normal;
        border-left: 1px solid $gray02;
        border-right: 1px solid $gray02;

        //  bg
        //----------------------------------------------------------------------
        &-bgBlue {
            background: $blueWhite;
        }
    }


    //======================================================================
    //  acc_select
    //======================================================================
    &_select {
        & > :first-child {
            margin-top: 0;
        }
    }

    &_selectList {
        padding: 0 15px;
        border-left: 1px solid $gray02;
        border-right: 1px solid $gray02;
        & > :first-child {
            border-top: none;
        }
    }

    &_selectItem {
        display: grid;
        grid-template-columns: 1fr auto;
        justify-content: space-between;
        gap: 0 15px;
        padding: 15px 0 10px;
        border-top: 1px dotted $gray04;
    }

    &_selectLeft {
        font-size: rem(15px, pc);
        & > :first-child {
            margin-top: 0;
        }
    }

    &_selectName {
        font-weight: bold;
    }

    &_selectTxts {
        margin: 13px 0 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0 17px;
        // メーカー削除
        // & > :first-child {
        //     text-indent: -5em;
        //     padding-left: 5em;
        // }
    }

    &_selectTxt {}

    &_selectRight {
        & > :first-child {
            margin-top: 0;
        }
    }

    // 入力ページで使用
    &_selectInput {
        margin: 5px 0 0;
        padding: 0 8px;
        width: 44px;
        height: 44px;
        font-size: rem(15px, pc);
        text-align: right;
        border: 1px solid $gray04;
        border-top: 1px solid $gray05;
        border-radius: 3px;
    }

    // 確認ページで使用
    &_selectRightNum {
        margin: 14px 0 0;
        font-size: rem(15px, pc);
        text-align: right;
    }
}

@media screen and (max-width: $breakpoint-sp){
    .acc {
        $this: &;

		&_inner {}

		&_list {}

		&_item {}

		&_btn {}

		&_content {}

		&_contentInner {}


        //======================================================================
        //  acc_scrollTable
        //======================================================================
		&_scrollTable {
            background: initial;
        }

		&_scrollTableTable {}

		&_scrollTableThead {}

		&_scrollTableTr {}

		&_scrollTableTh {}

		&_scrollTableTbody {}

		&_scrollTableTd {}


       //======================================================================
       //  acc_select
       //======================================================================
        &_select {}

        &_selectList {}

        &_selectItem {}

        &_selectLeft {}

        &_selectName {}

        &_selectTxts {}

        &_selectTxt {}

        &_selectRight {}

        &_selectInput {}
    }
}