.modalContents {
    &_inner {
        .pageHeader {
            &::after {
                width: 100%;
            }
        }
    }
    &_head {
    }
    &_text {
        font-size: rem(14px, pc);
        margin-top: 10px;
        text-align: center;
        line-height: 1.26;
        height: 40px;
        &-gray {
            color: #999;
            text-align: right;
        }
    }
    &_items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        margin-top: 0;
    }
    &_item {
        height: 120px;
        border: 1px solid #ddd;
        border-bottom: 2px solid #ddd;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;

        & > img {
            max-width: 51px;
            max-height: 58px;
            object-fit: contain;
        }
        
        &-isActive {
            border: 2px solid $blue;
            color: $blue;
        }
    }
}
