.menteHistory {
    &_inner {
        padding: 0 20px;
    }
    &_head {
        font-weight: 500;
        font-size: rem(15px, pc);
    }
    &_lists {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 30px;
        margin-top: 5px;
        gap: 10px 0;

        &-col {
            display: block;
        }
        &-3fr {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px 15px;
        }
    }
    &_list {
        display: list-item;
        font-size: rem(15px, pc);

        & > a {
            color: $blue;
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }
    &_dl {
        display: flex;
        border-top: 1px dotted #aaa;
        padding-top: 15px;
        padding-left: 5px;
        padding-right: 10px;
        margin-top: 15px;

        &-column {
            flex-direction: column;
            & > .menteHistory_dt {
                width: auto;
            }

            & > .menteHistory_dd {
                margin-left: 10px;
                
            }
        }
    }
    &_dt {
        font-weight: 600;
        font-size: rem(15px, pc);
        width: 70%;
    }
    &_dd {
        font-size: rem(15px, pc);
        display: flex;
        width: -webkit-fill-available;
        // flex-wrap: wrap;
        // align-items: flex-end;
        &-size {
            color: $blue;
            margin-left: 10px;
        }
        &-block {
            display: block;
        }
    }
    &_link {
        text-decoration: underline;
        color: $blue;
        text-underline-offset: 7px;
    }

    &_partsArea {
        display: flex;
    }

    &_partsColon {}

    &_partsBody {}

    &_partsLinkArea {}

    &_link {}

    &_partsBlocks {
        margin: 10px 0 0;
        & > :first-child {
            margin-top: 0;
        }
    }

    &_partsBlock {
        margin: 20px 0 0;
    }

    &_partsCate {
        margin: 0;
    }

    &_partsList {
        margin: 0;
    }

    &_partsItem {
        display: grid;
        grid-template-columns: 1fr auto;
        justify-content: space-between;
        gap: 0 10px;
        p {
            margin: 0;
        }
    }
}

