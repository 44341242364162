// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

// ========== 追加 ==========
[data-acc-root = open] {
	[data-acc-btn] {
		&::after {
			@include icons($icons-caret-up);
		}
	}
}
// ========== 追加 ==========
[data-acc] {}
[data-acc-btn] {}
[data-acc-content] {
	overflow: hidden;
	visibility: hidden;
	transition: max-height .4s;
}
