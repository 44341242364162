.topNews {
    background-color: $gray;
    margin-top: 40px;

    &_inner {
        padding: 30px 20px 20px;
    }

    &_title {
        font-size: rem(24px, pc);
        color: $blue;
        text-align: center;

    }

    &_lists {
        margin-top: 10px;
    }

    &_list {
        border-bottom: 1px dotted #aaa;
        padding: 8px 5px;
        line-height: 1.86;

        &:last-child {
            border-bottom: none;
        }

        &>a {
            display: flex;
            display: -webkit-flex;
            align-items: center;
            flex-wrap: wrap;
            -webkit-flex-wrap: wrap;
            gap: 10px;
            text-decoration: none;
            color: $txtColor;
            font-size: rem(15px, pc);
        }

        &More {
            color: $txtColor;
            text-decoration: none;
            font-size: rem(15px, pc);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;

            &::before {
                content: url(/assets/images/top/aroow.png);
            }
        }
    }

    &_date {}
}