.newsLists {
    &_inner {
        padding: 0 20px;
    }
    &_cate {
        padding: 0 10px;
    }
    &_text {
        font-size: rem(15px, pc);
        margin-top: 0;
    }
    &_select {
        border-top: 1px solid #a5a5a5;
        border-right: 1px solid #e2e2e2;
        border-left: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        border-radius: 5px;
        margin-top: 10px;
    }
    &_option {}
    &_lists {}
    &_item {
        border-bottom: 1px dotted #aaa;
        margin-bottom: 15px;
        padding: 0 10px 15px;

        &:last-child {
            border-bottom: none;
        }
    }
    &_text {
        font-size: rem(15px, pc);
        text-decoration: none;
        color: $txtColor;
    }
    &_data {
        font-size: rem(15px, pc);
        margin-bottom: 10px;
    }
    &_date {
        font-size: rem(15px, pc);
        margin-right: 10px;
    }
    &_cat {
        font-size: rem(13px, pc);
        background-color: #33bb77;
        color: white;
        padding: 5px 15px;
        border-radius: 5px;

        &-blue {
            background-color: $skyBlue;
        }
    }
    .pager {
        margin-top: 10px;
		&_inner {}
		&_list {
            margin: 0 10px;
        }
		&_prev {
            background-color: white;
            border-top: 1px solid $blue;
            border-right: 1px solid $blue;
            border-left: 1px solid $blue;
            border-bottom: 2px solid $blue;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            & > a {
                &::before {
                    color: $blue;
                    font-size: rem(12px, pc);
                    font-weight: bold;
                }
            }
        }
		&_item {}
		&_dot {
            font-size: rem(15px, pc);
        }
		&_item-active {
            & > a {
				background: $blue;
				border-color: transparent;
				color: #fff;
                border-radius: 5px;
			}
        }
		&_next {
            background-color: white;
            border-top: 1px solid $blue;
            border-right: 1px solid $blue;
            border-left: 1px solid $blue;
            border-bottom: 2px solid $blue;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            & > a {
                &::before {
                    color: $blue;
                    font-size: rem(12px, pc);
                    font-weight: bold;
                }
            }
        }
    }
}