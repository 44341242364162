// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine2

見出し2

Markup:
<h2 class="headLine2">見出し2</h2>

Styleguide #{$guideNum}
*/
.headLine2 {
	// $this: &;
	// padding-bottom: 10px;
	// border-bottom: 1px dotted #aaa;
	// padding-left: 15px;
	// font-size: rem(17px, pc);
	// font-weight: 500;
	
	margin: 40px 0 20px;
    padding: 12px 12px 12px 25px;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.4;
    position: relative;
	
	&::before {
		// content: "";
		// display: inline-block;
		// height: fit-content;
		// width: 3px;
		// background-color: #0081cc;
		content: "";
		display: block;
		background: #0081cc;
		width: 4px;
		border-radius: 4px;
		position: absolute;
		top: 8px;
		bottom: 8px;
		left: 12px;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine2 {
		$this: &;
	}
}
