.modification {
    &_tr {}
    &_th {
        vertical-align: baseline;
        background-color: #e2e2e2;
    }
    &_td {}
    &_items {
        display: flex;
        flex-direction: column;
    }
    &_text {
        margin-top: 0;
    }
    &_item {
        display: flex;
    }
    &_inpput {}
}
