
.gHeader {
    height: 68px;
    background-color: white;
    position: relative;
    z-index: 1001;
    &_inner {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }
    &_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 15px;
    }
    &_img {
    }
}
.gNav {
    height: 100%;
    overflow: visible;
    &_inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    &_items {
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 7px;
    }
    &_item {
    }
    &_link {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
    }
    &_text {
        margin-top: 0;
        font-size: rem(11px, pc);
        color: $txtColor;
        line-height: 1;
        margin-top: 7px;
    }
    &_btn {
        width: 60px;
        height: 100%;
        background-color: $blue;
        position: relative;
        display: flex;
        justify-content: center;

        &-active {
            z-index: 999;
            background-color: transparent;
            & > .gNav_spIcon {
                position: relative;
                width: 30px;
                height: 30px;
                cursor: pointer;
                & > p {
                    color: #aaa;
                    margin-top: 50px;
                    font-size: rem(10px, pc);
                }
                & > span {
                    transition: all 0.5s;
                    background-color: #aaa;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    display: block;
                    width: 30px;
                    height: 2px;
                    background-color: black;
                    &:nth-child(1) {
                        
                    }
                    &:nth-child(2) {
                        background-color: #aaa;
                    }
                    &:nth-child(3) {
                        width: 30px;
                        background-color: #aaa;
                        transform: translate(-50%, -50%) rotate(-45deg);
                    }
                }

            }
        }
    }
    &_spIcon {
        display: flex;
        z-index: 9999;
        
        justify-content: center;
        margin-top: 18px;
        flex-direction: column;
        gap: 6px;
        & > span {
            display: inline-block;
            width: 20px;
            margin: 0 auto;
            height: 1px;
            background-color: white;
            &:nth-child(1) {
                display: inline-block;
                width: 20px;
                margin: 0 auto;
                height: 1px;
                background-color: white;
            }
            &:nth-child(2) {
            }
            &:nth-child(3) {
                display: inline-block;
                width: 20px;
                margin: 0 auto;
                height: 1px;
                background-color: white;
            }
        }
        & > p {
            margin-top: 0;
            line-height: 1;
            color: white;
            font-size: rem(11px, pc);
            line-height: 1.8;
        }
    }

    &_closeBtn {
        background-color: $blue;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 100%;
        margin: 20px auto 0;
        
        & > button {
            color: white;
            
            &::after {
                content: "✕";
                font-weight: bold;
            }
        }
    }
}
.gNavSp {
    transition: all 0.5s;
    background-color: white;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    // transform: translateX(100%);
    opacity: 0;
    &-active {
        transform: none;
        opacity: 1;
        left: 0;
    }
    &_inner {
        width: 75%;
        margin: 0 auto;
    }
    &_items {
        margin-top: 70px;
    }
    &_item {
        margin-bottom: 5px;
    }
    &_link {
        text-decoration: underline;
        color: #000;
        font-size: rem(16px, pc);
        display: flex;
        align-items: center;
        gap: 10px;
        &-active {
            &::after {
                line-height: 1;
                content: url(/assets/images/header/loginIcon.png);
            }
        }
    }
}

.gridBlock {
    &-menu {
        transition: all 0.5s;
        background-color: white;
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateX(100%);
        &-active {
            transform: none;
            display: block;
        }
    }

    &_pc {
        .gridBlock_lists {
            margin-top: 30px;
        }
    }
    &_inner {
        padding: 0 20px;
    }
    &_head {
        margin-top: 20px;
        font-size: rem(24px, pc);
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
    }
    &_lists {
        // display: flex;
        // flex-wrap: wrap;
        gap: 10px;
        margin-top: 67px;
        // justify-content: center;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    &_list {
        border-top: 1px solid $blue;
        border-right: 1px solid $blue;
        border-left: 1px solid $blue;
        border-bottom: 2px solid $blue;
        border-radius: 5px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    &_item {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        &-active {
            &::after {
                position: absolute;
                bottom: -3px;
                right: 0px;
                content: url(/assets/images/top/active-icon.png);
                line-height: 1;
            }
        }
        & > img {
        }
    }
    &_text {
        margin-bottom: 10px;
        color: $blue;
        margin-top: 0;
        font-size: rem(14px, pc);
        line-height: 1.28;
        text-align: center;
        font-weight: 600;
    }
    
    &_img {
        & > img {
            width: 100%;
            height: 100px;
            object-fit: cover;
        }
    }
    &_wysiwyg {
        border: 1px solid #ddd;
        margin-top: 20px;
    }
    &_txt {
        margin-top: 0;
    }
}
