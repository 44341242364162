// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

/*
layout

大枠のレイアウト

Markup:
{$modifiers}

Styleguide #{$guideNum}
*/

.wrapper {
	$this: &;
	// min-width: calc($page-width + 40px);
	min-height: 100%;
	// overflow: hidden;
	& > :first-child {
		margin-top: 0;
	}
	width: $main-width;
	margin: 0 auto;
}

@media screen and (max-width: $breakpoint-sp){
	.wrapper {
		width: auto;
	}
}

.contents {
	$this: &;
	display: flex;
	display: -webkit-flex;
	justify-content: space-between;
	width: auto;
	margin: 0 auto;
	& > :first-child {
		margin-top: 0;
	}
	&_main {
		flex-grow: 0;
		flex-shrink: 0;
		width: $main-width;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_side {
		flex-grow: 0;
		flex-shrink: 0;
		width: $side-width;
		& > :first-child {
			margin-top: 0;
		}
	}
	&-noSide {
		display: block;
		#{$this}_main {
			width: auto;
		}
		#{$this}_side {
			display: none !important;
		}
	}
}

.section {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
	&_inner {
		max-width: $main-width;
		margin: 0 auto;
		& > :first-child {
			margin-top: 0;
		}
	}
	
	&_404 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&_title {
		font-size: rem(28px, pc);
	}
}

.section02 {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
	&_inner {
		max-width: $main-width;
		margin: 0 auto;
		padding: 0 20px;
		& > :first-child {
			margin-top: 0;
		}
	}
}

.sideSection {
	$this: &;
	& > :first-child {
		margin-top: 0;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.wrapper {
		$this: &;
		min-width: 100vw;
	}

	.contents {
		$this: &;
		display: block;
		width: auto;
		&_main {
			width: auto;
			& > :first-child {
				margin-top: 0;
			}
		}
		&_side {
			width: auto;
			& > :first-child {
				margin-top: 0;
			}
		}
		&-noSide {
		}
	}

	.section {
		$this: &;
		&_inner {
			margin: 0 20px;
		}
	}

	.section02 {
		$this: &;
		&_inner {}
	}

	.sideSection {
		$this: &;
	}
}