.productsBlock {
    &_inner {
        padding: 0 20px;

        .productsBlock_items:last-child {
            background-color: red;
        }
    }
    &_items {
        display: flex;
        padding: 0 10px 17px;
        margin-bottom: 17px;
        
        &-borderBottom {
            border-bottom: 1px dotted #aaa;
        }

        & > span {
            line-height: 1.6;
        }
    }
    &_dt {
        width: 50%;
        font-weight: bold;
        line-height: 1.6;
    }
    &_dd {
        width: 50%;
        line-height: 1.6;
    }

    &_link {
        text-decoration: underline;
        color: $blue;
        text-underline-offset: 7px;
    }

    &_logOut {}

    &_logIn {}

    &_text {

        &-gray {
            color: #999;
            display: flex;
            justify-content: center;
        }
    }
}
