.modal {
    // スマホでモーダルの中身が表示されなかった原因
    // =================================
    // position: fixed;
    // z-index: 9999;
    // top: 0;
    // right: 0;
    // left: 0;
    // bottom: 0;
    // background-color: rgba(0, 0, 0, 0.4);
    // =================================

    &_contentns {
        height: 165px;
    }
    &01 {
        &_inner {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        &_contents {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            padding: 0;
            width: 100%;
        }
        &_text {
            margin-top: 40px;
            font-size: rem(15px, pc);
        }
        .commonBtn {
            margin: 20px 60px 40px;
        }
    }
    &02 {
        &_inner {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        &_contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            padding: 0;
            width: 100%;
        }
        &_text {
            margin-top: 40px;
            font-size: rem(15px, pc);
        }
        &_btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin: 20px 0 40px;
        }
        &_btn {
            width: 160px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;

            &-blue {
                background-color: $blue;
                & > button {
                    color: white;
                }
            }

            &-white {
                background-color: white;
                border-top: 1px solid $blue;
                border-right: 1px solid $blue;
                border-left: 1px solid $blue;
                border-bottom: 2px solid $blue;

                & > a {
                    color: $blue;
                    text-decoration: none;
                }
            }
        }
    }
    &03 {
        &_inner {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        &_contents {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            padding: 0;
            width: 100%;
        }
        &_text {
            margin-top: 40px;
            font-size: rem(15px, pc);
        }
        &_btns {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin: 20px 0 40px;
        }
        &_btn {
            width: 165px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;

            &-blue {
                background-color: $blue;
                & > a {
                    color: white;
                    text-decoration: none;
                }
            }

            &-white {
                background-color: white;
                border-top: 1px solid $blue;
                border-right: 1px solid $blue;
                border-left: 1px solid $blue;
                border-bottom: 2px solid $blue;

                & > a {
                    color: $blue;
                    text-decoration: none;
                }
            }

            & > button {
                width: 100%;
                height: 100%;
            }
            & > a {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                font-size: rem(14px, pc);
            }
        }
    }
}
