.gFooter {
    border-top: 1px solid #ddd;
    margin-top: 40px;
    &_inner {
        padding: 25px 20px;
    }
    &_texts {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 0;
    }
    &_text {
        & > a {
            text-decoration: none;
            color: $txtColor;
            font-size: rem(14px, pc);
            &::before {
                padding-right: 5px;
                content: url(/assets/images/top/aroow.png);
            }
        }
    }
    &_copyRight {
        background-color: $txtColor;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 44px;
        & > small {
            font-size: rem(12px, pc);
            font-weight: bold;
            color: white;
        }
    }
    }
    .toTop {
        background-color: #aaa;
        width: 44px;
        height: 44px;
        position: fixed;
        bottom: 0;
        right: 0;
        
        & > a {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
    }

@media screen and (max-width: $breakpoint-sp){
    .gFooter {
		&_inner {}
		&_texts {}
		&_text {}
		&_copyRight {}
    }
    .toTop {
    }
}