// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine1

見出し1

Markup:
<h1 class="headLine1">見出し1</h1>

Styleguide #{$guideNum}
*/

.pageHeader {
	padding: 0 20px;
	&_text {
		font-size: rem(15px, pc);
	}

}
.headLine1 {
	line-height: 1;
	padding-bottom: 15px;
	$this: &;
	font-size: rem(18px, pc);
	// width: 33%;
	// border-bottom: 3px solid $blue;
	border-bottom: 3px solid  #e5e5e5;
	z-index: -1;
	font-weight: 500;
	margin-top: 20px;
	padding: 0 0 20px;
	position: relative;
	margin-bottom: 30px;
	font-weight: bold;

	&::after {
		content: "";
		display: inline-block;
		// width: calc(100% - 40px);
		width: 33%;
		height: 3px;
		// background-color: #e5e5e5;
		background-color: $blue;
		position: absolute;
		bottom: -3px;
		left: 0;
		z-index: 2;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine1 {
		$this: &;
	}
}
