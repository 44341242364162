// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';

.qrHistoryBlock {
    $this: &;

    &_inner {}

    &_txt {
        font-size: rem(15px, pc);
    }

    &_items {
        display: flex;
        gap: 0 14px;
        padding: 0 10px 17px;
        margin-bottom: 17px;

        &-borderBottom {
            border-bottom: 1px dotted #aaa;
        }
    }

    &_dt {
        width: 50%;
        font-weight: bold;
        line-height: 1.6;
    }

    &_dd {
        width: 50%;
        line-height: 1.6;
    }

    &_title {
        font-size: rem(15px, pc);
        font-weight: bold;
    }

    &_link {
        text-decoration: underline;
        color: $blue;
        text-underline-offset: 7px;
    }
}

@media screen and (max-width: $breakpoint-sp){
    .qrHistoryBlock {
        $this: &;

        &_inner {}

        &_txt {}

        &_items {}

        &_dt {}

        &_dd {}

        &_title {}

        &_link {}
    }
}
