//  breakpoints
//----------------------------------------------------------------------
$breakpoint-sp: 736px;

//  size
//----------------------------------------------------------------------
$page-width: 1200px; //仮
$main-width: 960px; //仮
$side-width: 210px; //仮

$font-size: (
	'pc': 16px, //仮
	'sp': 14px, //仮
) !default;
$lineHeight: calc(30/16);

//  colors
//----------------------------------------------------------------------

$txtColor: #333; //仮

$overlayBg: rgba(#000, .8); //仮

$red: #f00; //仮
$red-light: lighten($red, 10%); //仮

$blue: #0081cc; //仮
$skyBlue: #33bbff; //仮
$blueWhite: #eef9ff; //仮

$blue-light: lighten($blue, 10%); //仮

$gray: #f3f3f3; //仮
$gray02: #dddddd;
$gray03: #acacac;
$gray04: #aaaaaa;
$gray04: #e2e2e2;
$gray05: #a5a5a5;
$gray-light: lighten($gray, 10%); //仮
$gray-exLight: lighten($gray, 43%); //仮
$gray-border: $gray-light; //仮
$gray-bg: $gray-exLight; //仮

$black: #222; //仮
$oarange: #ff7700;

//  font
//----------------------------------------------------------------------
$fa-font-path: "/assets/fonts/font-awesome";
$fa-font-size-base: map-get($font-size, 'pc');
$fa-fw-width: calc(20em / 16);

@mixin far($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fa($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fas($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}
@mixin fab($code, $fw: true) {
	content: $code;
	font-family: 'Font Awesome 5 Brands';
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	@if $fw {
		text-align: center;
		width: $fa-fw-width;
	}
}

@mixin grade($from, $to, $vertical: false) {
	@if $vertical {
		background: $from;
		background: -moz-linear-gradient(top, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(top, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to bottom, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=0 );
	} @else {
		background: $from;
		background: -moz-linear-gradient(left, #{$from} 0%, #{$to} 100%);
		background: -webkit-linear-gradient(left, #{$from} 0%,#{$to} 100%);
		background: linear-gradient(to right, #{$from} 0%,#{$to} 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=1 );
	}
}

//px to rem
@function rem($px, $key: 'pc') {
	$value: map-get($font-size, $key);
	@return calc(($px / $value) * 1rem);
}

.border-top {
	border-top: 1px solid #ddd;
}
.border-right {
	border-right: 1px solid #ddd;
}
.border-bottom {
	border-bottom: 1px solid #ddd;
}
.border-left {
	border-left: 1px solid #ddd;
}
.border-top-none {
	border-top: none !important;
}
.bbn {
border-bottom: none !important;
}

.width-auto {
	overflow: visible !important;
	width: auto !important;
}

.contentsPadding {
	padding: 0 8px;
}

#shadow-root > span {
	color: red !important;
}