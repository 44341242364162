.newsDetail {
    &_inner {
        padding: 0 20px;
    }
    &_items {}
    &_item {}
    &_datas {}
    &_date {
        font-size: rem(15px, pc);
        margin-right: 5px;
    }
    &_cat {
        font-size: rem(13px, pc);
        background-color: $skyBlue;
        border-radius: 5px;
        color: white;
        padding: 2px 20px;
    }
    &_text {
        font-size: rem(15px, pc);
    }
}

