.bldgList {
    &_inner {
        padding: 0 20px;
    }
    &_contents {
    }
    &_items {
    }
    &_item {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 15px 15px 20px;
        margin-bottom: 15px;
        .commonBtn {
            width: 100%;
        }
    }
    &_header {
        padding-bottom: 10px;
        border-bottom: 1px dotted #aaa;
        .headLine2 {
            border-left: 3px solid #0081cc;
            padding-left: 15px;
            font-size: remZ(16px, pc);
            font-weight: 500;
        }
    }
    &_heading {
        margin-bottom: 10px;
        font-size: rem(15px, pc);
    }
    &_text {
        font-size: rem(15px, pc);
        line-height: 1.6;
        &-gray {
            color: #999;
            margin-top: 0;
        }

        &-memo {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            line-clamp: 4;
        }
    }
    &_box {
        display: flex;
    }
    &_left {
        width: 50%;
        & > ul {
            margin-top: 0;
            list-style: none;
            padding-left: 0;
            & > li {
                display: flex;
                align-items: center;
                line-height: 1.6;
                font-size: rem(15px, pc);
                &::before {
                    content: url(/assets/images/global/dot.png);
                    margin: 0 3px;
                }
            }
        }
    }
    &_right {
        width: 50%;
    }
    &_btns {
        display: flex;
        gap: 7px;
    }
    &_btn {
    }
}
