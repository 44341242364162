// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine5

見出し5

Markup:
<h5 class="headLine5">見出し5</h5>

Styleguide #{$guideNum}
*/
.headLine5 {
    margin: 40px 0 20px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.25rem;
    background: #f3f3f3;
    line-height: 1.4;
    font-weight: 700;

}

@media screen and (max-width: $breakpoint-sp){
	.headLine4 {
		$this: &;
	}
}
