.registerBlock {
    $this: &;
	&_modalContents {}
	&_box {
        text-align: center;
    }
	&_text {
        &-gray {
            display: inline;
            color: #a5a5a5;
            padding-left: 20px;
        }
    }
	&_btns {
        display: flex;
        gap: 10px;
    }

    &_inner {
        padding: 0 20px;
    }
    &_step {
    }
    &_stemItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &_stepItem {
        border: 1px solid $blue;
        padding: 20px;
        text-align: center;
        line-height: 1.5;
        border-radius: 5px;
        & > span {
            color: $blue;
            font-weight: 500;
        }

        &-step1 {
            position: relative;
            &::after {
                position: absolute;
                // right: -30px;
                right: -23px;
                content: url(/assets/images/registerBlock/triangle.png);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
            }
        }
        &-step2 {
            position: relative;
            &::after {
                position: absolute;
                // right: -30px;
                right: -23px;
                content: url(/assets/images/registerBlock/triangle.png);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
            }
        }

        &-page1 {
            border: 1px solid #aaa;

            & > span {
                color: #aaa;
            }
        }
        &-gray {
            border: 1px solid #aaa;

            & > span {
                color: #aaa;
            }
        }
        &-page1 {
            border: 1px solid #aaa;

            & > span {
                color: #aaa;
            }
        }
    }

    &_stepItem-en {
        font-size: rem(15px, pc);
    }
    &_stepItem-num {
        font-size: rem(20px, pc);
    }
    &_stepItem-ja {
        font-size: rem(16px, pc);
    }
    &_text {
        font-size: rem(15px, pc);
    }
    &_txt {
        font-size: rem(15px, pc);
    }

    &_table {
        padding: 10px 0;
        border-top: 2px solid #ddd;
        & > tbody {
        }
    }

    &_tr {
        border-bottom: 2px solid #ddd;
        
    }

    &_th {
        line-height: 1.6;
        background-color: #f3f3f3;
        font-size: rem(15px, pc);
    }
    &_td {
        font-size: rem(15px, pc);

        &-dot {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            & > span {
                line-height: .6;
                color: #888;
                font-weight: bold;
            }
        }
    }
    &_buttons {
        .commonBtn-blue {
            color: white;
        }
        .commonBtn-white {
            color: white;
            color: $blue;
        }
    }

    //  radio
    //----------------------------------------------------------------------
    &_radio {
        margin: 15px 0 0;
        font-size: rem(15px, pc);
    }

    &_radioInner {
        padding: 0 8px;
    }

    &_radioTitle {
        margin: 0;
    }

    &_radioCustomInputArea {
        margin: 10px 0 0;
        & > :first-child {
            margin-top: 0;
        }
    }

    &_radioCustomInput {
        margin: 10px 0 0;
    }

    &_radioCustomInputInner {
        &::before {
            content: '';
            border: 1px solid $gray03!important;
        }
    }

    //  registerBlock_check
    //----------------------------------------------------------------------
    &_check {
        margin: 25px 0 0;
    }

    &_checkTxts {
        margin: 0;
    }

    &_checkTxt {
        font-size: rem(15px, pc);
    }

    &_checkTable {
        margin: 0;
        font-size: rem(15px, pc);
    }

    &_checkTr {}

    &_checkTh,
    &_checkTd {
        padding: 10px 8px;
        border: 1px solid $gray02;
        border-left: none;
        border-right: none;
        vertical-align: top;
    }

    &_checkTh {
        min-width: 123px;
        font-weight: 400;
        background: $gray;
    }

    &_checkTd {}


    //  .registerBlock_strain
    //----------------------------------------------------------------------
    &_strain {
        & > :first-child {
            margin-top: 0;
        }
    }

    &_strainList {
        & > :first-child {
            margin-top: 0;
        }
    }

    &_strainItem {
        margin: 40px 0 0;
    }

}


.registerFormBlock {
    border-top: 1px dotted #aaa;
    margin-top: 15px;

    &_error {
        color: $red;
        margin-top: 5px;
        font-size: rem(14px, pc);
    }
    &_inner {
        padding: 15px 0 0;
    }
	&_dl {
        &-flex {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
	&_dt {

    }
	&_dd {

    }
	&_name {
        display: flex;
        align-items: center;
        overflow-wrap: anywhere;
        &::before {
            margin-right: 5px;
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: #000;
            border-radius: 50%;
            flex-shrink: 0;
        }
    }
    &_change {
        margin-top: 0;
        white-space: pre;
    }
    &_formItem {
        border-bottom: 1px dotted #aaa;
        padding-bottom: 15px;
        margin-bottom: 15px;
        padding: 0 10px 15px;

        & > label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 140px;
            height: 36px;
            background: linear-gradient(to bottom, #fff, #efefef);
            cursor: pointer;
            border: 1px solid #ddd;
            border-radius: 5px;
            margin-top: 13px;
            input[type="file"] {
                display: none;
            }
        }
        
        &-dot {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & > span {
                line-height: 1;
                color: #888;
                font-weight: bold;
            }
        }
    }
    &_fileName {
        font-size: rem(14px, pc);
        color: #a5a5a5;
        margin-top: 0;
    }
    &_fileSize {
        font-size: rem(14px, pc);
        color: #a5a5a5;
        margin-top: 0;
    }
    &_texts {
        font-size: rem(15px, pc);
        display: flex;
        align-items: center;
        gap: 7px;
    }
    &_text-required {
    }
    &_text {
        font-size: rem(15px, pc);
        &-link {
            text-decoration: underline;
            text-underline-offset: 8px;
            color: $blue;

            &::before {
                content: url(/assets/images/contact/icon01.png);
            }
        }
        &-14 {
            font-size: rem(14px, pc);
        }
        &-required {
            background-color: #dd0000;
            color: #fff;
            width: 36px;
            height: 22px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            line-height: 0;
            border-radius: 5px;
        }

        &-any {
            background-color: #999;
            color: #fff;
            width: 36px;
            height: 22px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            line-height: 0;
            border-radius: 5px;
        }

        &-gray {
            margin-left: 14px;
            color: #a5a5a5;
        }
        margin-top: 0;
    }
    &_inputArea {
        display: flex;
        align-items: center;
        margin-top: 11px;
        gap: 10px;

        &-50 {
            width: 50%;
        }
        select {
            border-top: 1px solid #a5a5a5;
            border-right: 1px solid #e2e2e2;
            border-left: 1px solid #e2e2e2;
            border-bottom: 1px solid #e2e2e2;
            border-radius: 5px;
        }
    }
    &_select {
        
    }
    &_input {
        border-top: 1px solid #a5a5a5;
        border-right: 1px solid #e2e2e2;
        border-left: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        border-radius: 5px;
        height: 43px;
        width: 100%;
    }
    &_btn {
        border: 1px solid #ddd;
        background-color: #fcfcfc;
        min-width: 60px;
        height: 35px;
        border-radius: 5px;
        font-size: rem(15px, pc);
        background: linear-gradient(to bottom, #fff, #efefef);
        text-decoration: none;
        color: #333;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &_searchBtn {
        & > a {
            text-decoration: none;
            color: #333;
            font-size: rem(14px, pc);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }
    &_textArea {
        height: 120px;
        border-top: 1px solid #a5a5a5;
        border-right:1px solid #e2e2e2;
        border-left:1px solid #e2e2e2;
        border-bottom:1px solid #e2e2e2;
        margin-top: 10px;
        
        & > textarea {
            height: 100%;
        }
    }
    &_box {
        border: 2px solid #e5e5e5;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 15px 0;
    }
    &_link {
        text-decoration: none;
        text-decoration: underline;
        color: #0081cc;
        text-underline-offset: 7px;
        font-size: rem(15px, pc);
    }
    &_label {
        display: flex;
        justify-content: center;
        align-items: center;

    }
    &_check {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        border: 1px solid #aaa;
        border-radius: 5px;
    }
    &_button {
        & > .commonBtn {
            color: #fff;
        }
    }
	&_checks {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        &02 {
            display: flex;
            align-items: center;
            margin-top: 10px;
            gap: 15px;

            & > label {
                display: flex;
                align-items: center;
                gap: 6px;
                & > input {
                    border-radius: 50%;
                    width: 18px;
                    height: 18px;

                    &[type=checkbox] {
                        appearance: none;
                        border-radius: 50%;
                        width: 16px;
                        height: 16px;
                        border: 1px solid #ccc;

                        &:checked {
                            background-image: url(/assets/images/contact/check-mark.png);
                            background-size: 100% 100%;
                            background-repeat: no-repeat;
                            background-position: center;
                        }
                    }
                }
            }
        }
        & > label {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }
}

.border-none {
    border-bottom: none;
}
