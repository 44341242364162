// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
pankuzu

パンクズ

Markup:
<nav class="pankuzu">
	<div class="pankuzu_inner">
		<ul class="pankuzu_list">
			<li class="pankuzu_item"><a href="/">トップ</a></li>
			<li class="pankuzu_item"><a href="./">カテゴリタイトル</a></li>
			<li class="pankuzu_item">ページタイトル</li>
		</ul>
	</div>
</nav><!-- /.pankuzu -->

Styleguide #{$guideNum}
*/
.pankuzu {
	$this: &;
	margin: 20px 0 0;
	& > :first-child {
		margin-top: 0;
	}
	&_inner {
		margin: 0 auto;
		padding-left: 20px;
		width: auto;
		overflow-x: auto;
		& > :first-child {
			margin-top: 0;
		}
	}
	&_list {
		margin: 0;
		display: flex;
		display: -webkit-flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		flex-direction: row;
		& > :first-child {
			&:before {
				content: none;
			}
		}
	}
	&_item {
		font-size: rem(12px, pc);
		color: #999;
		overflow-wrap: anywhere;
		&-home {
			& > a {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 5px;
				text-decoration: none;
				color: #999;
				&::before {
					content: url(/assets/images/global/home.png);
					line-height: 1;
				}
			}
		}
		& > a {
			color: #999;
			font-size: rem(13px, pc);
		}
		&:before {
			content: '　>　';
		}
	}
}

@media screen and (max-width: $breakpoint-sp){
	.pankuzu {
		$this: &;
		&_inner {
			width: auto;
			overflow-x: auto;
		}
		&_list {
			flex-wrap: nowrap;
		}
		&_item {
			font-size: rem(11px, pc);
			white-space: nowrap;
		}
	}
}
