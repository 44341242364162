.ledgerList {
    &_inner {
        padding: 0 20px;
    }
    &_items {

    }
    &_item {
        padding: 0 10px;
        border-bottom: 1px dotted #aaa;
        padding-bottom: 15px;
        margin-bottom: 10px;
    }
    &_text {
        margin-top: 0;
    }
    &_select {
        border-top: 1px solid #a5a5a5;
        border-right: 1px solid #e2e2e2;
        border-left: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        border-radius: 5px;
        margin-top: 8px;
    }
    &_option {
    }
    &_calendar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        flex-direction: column;
        margin-top: 10px;
    }
    &_inputArea {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        gap: 10px;
    }
    &_date {
        border-top: 1px solid #a5a5a5;
        border-right: 1px solid #e2e2e2;
        border-left: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        flex-direction: row-reverse;
        border-radius: 5px;
        position: relative;
        width: 100%;
        background-image: url(/assets/images/global/date.png);
        background-repeat: no-repeat;
        background-position: 5px;
        text-align: center;
        
        &[type="date" i]::-webkit-calendar-picker-indicator {
            background-position: 10px;
        }
        &::-webkit-datetime-edit {
            text-align: center !important;
        }
    }

    &_searchBtn {
        width: 100%;
        height: 40px;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        & > button {
            color: white;
            height: 100%;
            width: 100%;
            font-size: rem(14px, pc);
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .pager {
		&_inner {}
		&_list {
            margin: 0 10px;
        }
		&_prev {
            background-color: white;
            border-top: 1px solid $blue;
            border-right: 1px solid $blue;
            border-left: 1px solid $blue;
            border-bottom: 2px solid $blue;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            & > a {
                &::before {
                    color: $blue;
                    font-size: rem(12px, pc);
                    font-weight: bold;
                }
            }
        }
		&_item {}
		&_dot {
            font-size: rem(15px, pc);
        }
		&_item-active {
            & > a {
				background: $blue;
				border-color: transparent;
				color: #fff;
                border-radius: 5px;
			}
        }
		&_next {
            background-color: white;
            border-top: 1px solid $blue;
            border-right: 1px solid $blue;
            border-left: 1px solid $blue;
            border-bottom: 2px solid $blue;
            width: 40px;
            height: 40px;
            border-radius: 5px;
            & > a {
                &::before {
                    color: $blue;
                    font-size: rem(12px, pc);
                    font-weight: bold;
                }
            }
        }
    }

}
