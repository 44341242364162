.registerCheckBlock {
    &_inner {}
    &_text {}
    &_item {
        padding: 0 20px;
    }
    &_dl {
        &-flex {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
    &_dt {
        font-weight: bold;
    }
    &_dd {}
    &_fileName {}
    &_capacity {}
    &_head {}
    &_btns {
        margin-top: 40px;
    }
    &_btn {}
}