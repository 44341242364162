// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine6

見出し6

Markup:
<h2 class="headLine6">見出し6</h2>

Styleguide #{$guideNum}
*/
.headLine6 {
	$this: &;
	margin: 30px 0 20px;
    padding: 0 13px 15px;
    font-size: rem(17px, pc);
    font-weight: 600;
    border-bottom: 1px dotted #aaa;
    line-height: 1.6;
    position: relative;

	&::before {
		content: '';
        position: absolute;
        top: 0;
        left: 0;
		width: 3px;
		height: 28px;
		background: #0081cc;
	}
}

@media screen and (max-width: $breakpoint-sp){
	.headLine6 {
		$this: &;
	}
}
