// @import '../_config';
// @import '../_iconFonts';
// @import '../_svgSprite';
// @import '../_imgSprite';


/*
headLine4

見出し4

Markup:
<h4 class="headLine4">見出し4</h4>

Styleguide #{$guideNum}
*/
.headLine4 {
	$this: &;
	margin: 40px 0 20px;
    padding: 0 0 5px;
    font-size: 1.375rem;
    border-bottom: 1px dotted #aaa;
    line-height: 1.4;
    font-weight: 700;
}

@media screen and (max-width: $breakpoint-sp){
	.headLine4 {
		$this: &;
	}
}
