@import './_config';

//mqpackerの順番制御用
@media screen and (min-width: $breakpoint-sp + 1) { .mqDummy {width: auto;} }
@media screen and (min-width: $breakpoint-sp) { .mqDummy {width: auto;} }
@media screen and (max-width: $breakpoint-sp) { .mqDummy {width: auto;} }

@import './_iconFonts';
// @import './_iconFontsClass';
@import './_svgSprite';
@import './_imgSprite';

// font-awesome使うなら以下必要
// @import './_font-awesome/_solid';
// @import './_font-awesome/_regular';
// @import './_font-awesome/_brands';
// @import './_font-awesome/_fontawesome';


//default style
@import "_default";

/*
便利クラス

マージン強制とかの便利クラス

Styleguide 1.
*/
$guideNum: '1.1'; @import './_shared/_responsiveHidden';
$guideNum: '1.2'; @import './_shared/_super';



/*
グローバル

gNavとかgHeaderとか
大まかなレイアウトもここに

Styleguide 3.
*/

$guideNum: '3.1'; @import './_global/_layout';
$guideNum: '3.2'; @import './_global/_overlay';
$guideNum: '3.3'; @import './_global/_gHeader';
$guideNum: '3.4'; @import './_global/_gFooter';

/*
パーツ

見出しとかボタンとかアイコン付きの文字とか

Styleguide 4.
*/
$guideNum: '4.1'; @import './_parts/_font';
$guideNum: '4.2'; @import './_parts/_color';
$guideNum: '4.3'; @import './_parts/_btn';
$guideNum: '4.4'; @import './_parts/_btns';
$guideNum: '4.5.1'; @import './_parts/_headLine1';
$guideNum: '4.5.2'; @import './_parts/_headLine2';
$guideNum: '4.5.3'; @import './_parts/_headLine3';
$guideNum: '4.5.4'; @import './_parts/_headLine4';
$guideNum: '4.5.5'; @import './_parts/_headLine5';
$guideNum: '4.5.6'; @import './_parts/_headLine6';
$guideNum: '4.6'; @import './_parts/_customInput';
$guideNum: '4.7'; @import './_parts/_responsiveTable';
$guideNum: '4.8'; @import './_parts/_scrollTable';
$guideNum: '4.9'; @import './_parts/_dataThTable';
$guideNum: '4.10'; @import './_parts/_youtube';


/*
モジュール

その他モジュール


Styleguide 5.
*/
$guideNum: '5.1'; @import './_modules/_pankuzu';
$guideNum: '5.2'; @import './_modules/_pager';
// $guideNum: '5.3'; @import './_modules/_form';
$guideNum: '5.4'; @import './_modules/_fileForm';
$guideNum: '5.5'; @import './_modules/_imgTxt';
$guideNum: '5.6'; @import './_modules/_grid';
// $guideNum: '5.7.1'; @import './_modules/_treeMenu';
// $guideNum: '5.7.2'; @import './_modules/_treeMenu2';
$guideNum: '5.7.3'; @import './_modules/_swiper';
$guideNum: '5.7.4'; @import './_modules/_topNews';
$guideNum: '5.7.5'; @import './_modules/_loginForm';
$guideNum: '5.7.6'; @import './_modules/_reissueBlock';
$guideNum: '5.7.7'; @import './_modules/_reissueCompletionBlock';
$guideNum: '5.7.8'; @import './_modules/_registerBlock';
$guideNum: '5.7.9'; @import './_modules/_questionnaireBlock';
$guideNum: '5.7.9'; @import './_modules/_productsBlock';
$guideNum: '5.8.1'; @import './_modules/_addProducts';
$guideNum: '5.8.2'; @import './_modules/_strainInput';
$guideNum: '5.8.3'; @import './_modules/_procuctRegister';
$guideNum: '5.8.4'; @import './_modules/_bldgList';
$guideNum: '5.8.5'; @import './_modules/_managementBldg';
$guideNum: '5.8.6'; @import './_modules/_menteHistory';
$guideNum: '5.8.7'; @import './_modules/_ledgerList';
$guideNum: '5.8.8'; @import './_modules/_strainNameArea';
$guideNum: '5.8.9'; @import './_modules/_modalContents';
$guideNum: '5.9'; @import './_modules/_newsList';
$guideNum: '5.9.1'; @import './_modules/_newsDetail';
$guideNum: '5.9.2'; @import './_modules/_modal';
$guideNum: '5.9.4'; @import './_modules/_modification';
$guideNum: '5.9.5'; @import './_modules/_registerCheckBlock';
$guideNum: '5.9.6'; @import './_modules/_registerEnd';
$guideNum: '5.10'; @import './_modules/_qrHistoryBlock';
$guideNum: '5.11'; @import './_modules/_acc';
$guideNum: '5.12'; @import './_modules/_check';

/*
サイド

サイド用のクラス

Styleguide 6.
*/
// $guideNum: '6.1'; @import './_side/xxxxxxxxx';

//============================================================
//  pages
//============================================================
// @import './pages/xxxxxxxxx';


//============================================================
// js
//
// js関連の最低限のスタイル　基本いじらない
// 使い方はフロント開発テンプレートDoc参照
//============================================================
@import './_js/_customDialog';
@import './_js/_contentsModal';
@import './_js/_accordion';
@import './_js/_accordion02';
// @import './_js/_tab';
// @import './_js/_tree';
// @import './_js/_objectFitPolyfil';
// @import './_js/_validator'


//editor用デフォルトテンプレートscss
@import './_editorTmpl/_index';

#container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  object-fit: cover;
  display: none;
}

#canvas {
  display: none;
}