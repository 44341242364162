.swiper {
    position: relative;
    overflow: hidden;
    padding-bottom: 1px;

    &_options {
        height: 50px;
        position: relative;
        width: 200px;
        margin: 0 auto;
    }
    &-pagination-bullet {
        background-color: white;
        border: 1px solid #999;
        width: 13px;
        height: 13px;
    }
    &-pagination-bullet-active {
        background-color: #999;
        width: 17px;
        height: 17px;
    }
    &_wrapper {
    }
    &_slide {
        width: 100% !important;
        & > img {
            width: 100%;
            object-fit: contain;
        }
    }
    &_pagination {
        position: absolute !important;
        bottom: 5px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 25px;
    }
    .swiper-button {
            position: absolute;
            &_prev {
                box-shadow: 0 2px 0 0 $blue;
                line-height: 0;
                border: 1px solid $blue;
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: absolute;
                top: 35px;
                z-index: 1000;
                &::after {
                    content: url(/assets/images/top/prev.png);
                    line-height: 0;
                    border: 1px solid $blue;
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                }
            }
            &_next {
                box-shadow: 0 2px 0 0 $blue;
                position: absolute;
                top: 35px;
                line-height: 0;
                border: 1px solid $blue;
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                z-index: 1000;
                &::after {
                    content: url(/assets/images/top/next.png);
                    line-height: 0;
                }
            }
    }
}

@media screen and (max-width: $breakpoint-sp) {
    .swiper {
        &_wrapper {
        }
        &_slide {
        }
        &_pagination {
        }
    }
}
