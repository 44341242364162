.questionnaireBlock {
    &_inner {
        padding: 0 20px;
    }
    &_items {}
    &_item {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 16px;
        border-bottom: 1px dotted #aaa;
        padding: 0 10px 16px;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        & > a {
            text-decoration: underline;
            color: $blue;
            font-size: rem(15px, pc);
            text-underline-offset: 6px;
        }
    }

    &_cat {
        width: 90px;
        margin-top: 0;
        font-weight: 500;
    }
    &_text {
        margin-top: 0;
        line-height: 1.2;
        font-weight: 500;
    }
    &_date {
        line-height: 1.6;
    }

}
