.addProducts {
    &_inner {
        padding: 0 20px;
    }
    &_contents {
    }
    &_form {
    }
    &_formItem {
        margin: 0 10px;
        &-border {
            border-bottom: 1px dotted #aaaaaa;
            padding-bottom: 15px;
        }
    }
	&_dl {

    }
	&_dt {

    }
	&_head {
        font-weight: bold;
        font-size: rem(16px, pc);
        margin-bottom: 10px;
    }
	&_dd {
        position: relative;
        &::after {
            content: "▼";
            position: absolute;
            right: 20px;
            top: 5px;
        }

    }
	&_select {
        border: 1px solid $gray;
        -webkit-appearance: none;
        appearance: none; /* デフォルトの矢印を非表示 */
    }
	&_option {

    }

    &_text {
        display: flex;
        gap: 7px;
        margin-bottom: 9px;
        line-height: 1.66;
        &-required {
            background-color: #dd0000;
            color: white;
            border-radius: 5px;
            line-height: 1.66;
            padding: 0 6px;
        }
        &-any {
            background-color: #999;
            color: white;
            border-radius: 5px;
            line-height: 1.66;
            padding: 0 6px;
        }
        &-gray {
            color: #999;
        }
    }
    &_input {
        border-top: #e5e5e5;
        & > input {
            border-radius: 5px;
            border: 1px solid #e2e2e2;

            &::placeholder {
                color: #a0a0a0;
            }
        }
    }
    &_checks {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px 0;
        .customInput {
            &_inner {
                font-size: rem(15px, pc);
                &::before {
                    border: 1px solid #aaaaaa;
                    height: 18px;
                    width: 18px;
                }
            }
        }
    }

    &_btns {
        display: flex;
        align-items: center;
        margin: 30px 0 10px;
        gap: 8px;
    }
    &_btn {
        border: 1px solid #ddd;
        background-color: #fcfcfc;
        width: 72px;
        height: 36px;
        border-radius: 5px;
        font-size: rem(15px, pc);
        background: linear-gradient(to bottom, #fff, #efefef);
    }
    &_scrollTable {
        height: 370px;
        overflow: auto;
        
        &-height100 {
            height: 100px;
        }
    }
    &_table {
        min-width: 100%;
        width: auto;
        white-space: nowrap;
        overflow: auto;
        margin-top: 0;
    }
    &_tbody {
    }
    &_tr {
    }
    &_th {
        font-size: rem(15px, pc);
        color: #333;
        text-align: center;
        &-gray {
            background-color: #f3f3f3;
        }
        &-triangle {
            &::after {
                content: url(/assets/images/addProducts/curcle.png);
                line-height: 1;
                margin-left: 5px;
            }
        }
    }
    &_td {
        font-size: rem(15px, pc);
        color: #333;
        text-align: center;
    }
    &_download {
        text-decoration: underline;
        color: $skyBlue;
        text-underline-offset: 7px;
    }
    &End {
        &_inner {
        }
        &_title {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            font-size: rem(20px, pc);
            margin-bottom: 20px;
        }
        &_btns {

        }
        &_btn {

        }    
    }
}
