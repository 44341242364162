.managementBldg {
    &_inner {
        padding: 0 20px;
    }
    &_links {
        display: grid;
        grid-template-columns: 1fr 1fr;

        &-01 {
            padding-left: 10px;
        }
        &-02 {
            margin-left: 30px;
            margin-top: 10px;
        }
    }
    &_link {
        & > a {
            text-decoration: none;
            color: $blue;
        }
        &-curcle {
            display: list-item;
            & > a {
                text-decoration: underline;
                text-underline-offset: 7px;
            }
            &::marker {
                font-size: 20px;
                color: #333;
            }
        }
        &-down {
            display: flex;
            align-items: center;
            gap: 5px;
            &::before {
                content: url(/assets/images/global/down.png);
                line-height: 0;
            }
        }
    }
    &_btn {
        text-decoration: none;
        border: 1px solid $blue;
        margin-top: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: $blue;
    }
    &_btnLink {
        text-decoration: none;
        border: 1px solid $blue;
        margin-top: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: $blue;
        & > a {
            text-decoration: none;
            color: $txtColor;
        }
    }
    &_items {
    }
    &_item {
        margin-top: 25px;
    }
    &_flex {
        display: flex;
        flex-direction: column;
        width: 215px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    &_txt {
        margin-top: 20px;
    }
    &_text {
        margin-top: 0;
        font-size: rem(15px, pc);
        line-height: 1.6;
        &-bold {
            font-weight: 500;
        }
    }
    &_lists {
    }
    &_list {
        gap: 10px;
        display: flex;
        align-items: baseline;
        border-bottom: 1px dotted #aaa;
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:last-child {
            border-bottom: none;
        }
    }
    &_item {
    }
    &_text-bold {
    }
    &_date {
        width: 100px;
        margin-top: 0;
    }
    &_editBtn {
        background: linear-gradient(to bottom, #fff, #efefef);
        display: inline-block;
        width: 78px;
        height: 36px;
        border: 1px solid #ddd;
        border-radius: 5px;
        
        & > a {
            text-decoration: none;
            color: $txtColor;
            font-size: rem(15px, pc);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            gap: 2px;
            &::before {
                line-height: 0;
                content: url(/assets/images/global/btn/edit.png);
            }
        }
    }
    &_link {
        text-decoration: underline;
        color: $blue;
        text-underline-offset: 6px;
        width: 215px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}