.strainInput {
    &_inner {
        padding: 0 20px;
    }
    &_text {
        
        &-gray {
            color: #999;
            font-size: rem(15px, pc);
        }
    }
    &_inputArea {
    }
    &_flex {
        display: flex;
        border-top: 1px dotted #aaa;
        gap: 15px;
        margin-top: 15px;
        padding: 15px 10px 0px;
        
        &:last-of-type {
        }

        &:first-child {
            border-top: none;
            margin-top: 10px;
            padding-top: 0;
        }
        & > input {
            border-top: 1px solid #a5a5a5;
            border-right: 1px solid #e5e5e5;
            border-left: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;
            border-radius: 5px;
            height: 45px;
        }
    }
    &_cat {
        margin-top: 0;
        font-size: rem(15px, pc);
        font-weight: 500;
        width: 80px;
    }
    &_name {
        margin-top: 0;
        font-size: rem(15px, pc);
        font-weight: 500;
        line-height: 1.6;
    }
    &_num {
        margin-top: 0;
        font-size: rem(15px, pc);
        line-height: 1.6;
    }
}

